import * as wasm from './red_simulation_bg.wasm';

const heap = new Array(32).fill(undefined);

heap.push(undefined, null, true, false);

function getObject(idx) { return heap[idx]; }

let heap_next = heap.length;

function dropObject(idx) {
    if (idx < 36) return;
    heap[idx] = heap_next;
    heap_next = idx;
}

function takeObject(idx) {
    const ret = getObject(idx);
    dropObject(idx);
    return ret;
}

const lTextDecoder = typeof TextDecoder === 'undefined' ? (0, module.require)('util').TextDecoder : TextDecoder;

let cachedTextDecoder = new lTextDecoder('utf-8', { ignoreBOM: true, fatal: true });

cachedTextDecoder.decode();

let cachegetUint8Memory0 = null;
function getUint8Memory0() {
    if (cachegetUint8Memory0 === null || cachegetUint8Memory0.buffer !== wasm.memory.buffer) {
        cachegetUint8Memory0 = new Uint8Array(wasm.memory.buffer);
    }
    return cachegetUint8Memory0;
}

function getStringFromWasm0(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory0().subarray(ptr, ptr + len));
}

function addHeapObject(obj) {
    if (heap_next === heap.length) heap.push(heap.length + 1);
    const idx = heap_next;
    heap_next = heap[idx];

    heap[idx] = obj;
    return idx;
}

function debugString(val) {
    // primitive types
    const type = typeof val;
    if (type == 'number' || type == 'boolean' || val == null) {
        return  `${val}`;
    }
    if (type == 'string') {
        return `"${val}"`;
    }
    if (type == 'symbol') {
        const description = val.description;
        if (description == null) {
            return 'Symbol';
        } else {
            return `Symbol(${description})`;
        }
    }
    if (type == 'function') {
        const name = val.name;
        if (typeof name == 'string' && name.length > 0) {
            return `Function(${name})`;
        } else {
            return 'Function';
        }
    }
    // objects
    if (Array.isArray(val)) {
        const length = val.length;
        let debug = '[';
        if (length > 0) {
            debug += debugString(val[0]);
        }
        for(let i = 1; i < length; i++) {
            debug += ', ' + debugString(val[i]);
        }
        debug += ']';
        return debug;
    }
    // Test for built-in
    const builtInMatches = /\[object ([^\]]+)\]/.exec(toString.call(val));
    let className;
    if (builtInMatches.length > 1) {
        className = builtInMatches[1];
    } else {
        // Failed to match the standard '[object ClassName]'
        return toString.call(val);
    }
    if (className == 'Object') {
        // we're a user defined class or Object
        // JSON.stringify avoids problems with cycles, and is generally much
        // easier than looping through ownProperties of `val`.
        try {
            return 'Object(' + JSON.stringify(val) + ')';
        } catch (_) {
            return 'Object';
        }
    }
    // errors
    if (val instanceof Error) {
        return `${val.name}: ${val.message}\n${val.stack}`;
    }
    // TODO we could test for more things here, like `Set`s and `Map`s.
    return className;
}

let WASM_VECTOR_LEN = 0;

const lTextEncoder = typeof TextEncoder === 'undefined' ? (0, module.require)('util').TextEncoder : TextEncoder;

let cachedTextEncoder = new lTextEncoder('utf-8');

const encodeString = (typeof cachedTextEncoder.encodeInto === 'function'
    ? function (arg, view) {
    return cachedTextEncoder.encodeInto(arg, view);
}
    : function (arg, view) {
    const buf = cachedTextEncoder.encode(arg);
    view.set(buf);
    return {
        read: arg.length,
        written: buf.length
    };
});

function passStringToWasm0(arg, malloc, realloc) {

    if (realloc === undefined) {
        const buf = cachedTextEncoder.encode(arg);
        const ptr = malloc(buf.length);
        getUint8Memory0().subarray(ptr, ptr + buf.length).set(buf);
        WASM_VECTOR_LEN = buf.length;
        return ptr;
    }

    let len = arg.length;
    let ptr = malloc(len);

    const mem = getUint8Memory0();

    let offset = 0;

    for (; offset < len; offset++) {
        const code = arg.charCodeAt(offset);
        if (code > 0x7F) break;
        mem[ptr + offset] = code;
    }

    if (offset !== len) {
        if (offset !== 0) {
            arg = arg.slice(offset);
        }
        ptr = realloc(ptr, len, len = offset + arg.length * 3);
        const view = getUint8Memory0().subarray(ptr + offset, ptr + len);
        const ret = encodeString(arg, view);

        offset += ret.written;
    }

    WASM_VECTOR_LEN = offset;
    return ptr;
}

let cachegetInt32Memory0 = null;
function getInt32Memory0() {
    if (cachegetInt32Memory0 === null || cachegetInt32Memory0.buffer !== wasm.memory.buffer) {
        cachegetInt32Memory0 = new Int32Array(wasm.memory.buffer);
    }
    return cachegetInt32Memory0;
}

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
    return instance.ptr;
}

function isLikeNone(x) {
    return x === undefined || x === null;
}

let stack_pointer = 32;

function addBorrowedObject(obj) {
    if (stack_pointer == 1) throw new Error('out of js stack');
    heap[--stack_pointer] = obj;
    return stack_pointer;
}

const u32CvtShim = new Uint32Array(2);

const uint64CvtShim = new BigUint64Array(u32CvtShim.buffer);
/**
*/
export function set_panic_hook() {
    wasm.set_panic_hook();
}

function handleError(f, args) {
    try {
        return f.apply(this, args);
    } catch (e) {
        wasm.__wbindgen_exn_store(addHeapObject(e));
    }
}
/**
*/
export class DrawParams {

    static __wrap(ptr) {
        const obj = Object.create(DrawParams.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_drawparams_free(ptr);
    }
    /**
    */
    get borders() {
        const ret = wasm.__wbg_get_drawparams_borders(this.ptr);
        return ret !== 0;
    }
    /**
    * @param {boolean} arg0
    */
    set borders(arg0) {
        wasm.__wbg_set_drawparams_borders(this.ptr, arg0);
    }
    /**
    * @param {boolean} borders
    * @returns {DrawParams}
    */
    static new(borders) {
        const ret = wasm.drawparams_new(borders);
        return DrawParams.__wrap(ret);
    }
}
/**
*/
export class Line {

    static __wrap(ptr) {
        const obj = Object.create(Line.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_line_free(ptr);
    }
    /**
    */
    get a() {
        const ret = wasm.__wbg_get_line_a(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set a(arg0) {
        wasm.__wbg_set_line_a(this.ptr, arg0);
    }
    /**
    */
    get b() {
        const ret = wasm.__wbg_get_line_b(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set b(arg0) {
        wasm.__wbg_set_line_b(this.ptr, arg0);
    }
    /**
    */
    get c() {
        const ret = wasm.__wbg_get_line_c(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set c(arg0) {
        wasm.__wbg_set_line_c(this.ptr, arg0);
    }
}
/**
*/
export class Particle {

    static __wrap(ptr) {
        const obj = Object.create(Particle.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_particle_free(ptr);
    }
    /**
    */
    get pos() {
        const ret = wasm.__wbg_get_particle_pos(this.ptr);
        return Vec2.__wrap(ret);
    }
    /**
    * @param {Vec2} arg0
    */
    set pos(arg0) {
        _assertClass(arg0, Vec2);
        var ptr0 = arg0.ptr;
        arg0.ptr = 0;
        wasm.__wbg_set_particle_pos(this.ptr, ptr0);
    }
    /**
    */
    get v() {
        const ret = wasm.__wbg_get_particle_v(this.ptr);
        return Vec2.__wrap(ret);
    }
    /**
    * @param {Vec2} arg0
    */
    set v(arg0) {
        _assertClass(arg0, Vec2);
        var ptr0 = arg0.ptr;
        arg0.ptr = 0;
        wasm.__wbg_set_particle_v(this.ptr, ptr0);
    }
    /**
    */
    get m() {
        const ret = wasm.__wbg_get_particle_m(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set m(arg0) {
        wasm.__wbg_set_particle_m(this.ptr, arg0);
    }
    /**
    */
    get r() {
        const ret = wasm.__wbg_get_particle_r(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set r(arg0) {
        wasm.__wbg_set_particle_r(this.ptr, arg0);
    }
    /**
    */
    get collisions_count() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.__wbg_get_particle_collisions_count(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            u32CvtShim[0] = r0;
            u32CvtShim[1] = r1;
            const n0 = uint64CvtShim[0];
            return n0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {BigInt} arg0
    */
    set collisions_count(arg0) {
        uint64CvtShim[0] = arg0;
        const low0 = u32CvtShim[0];
        const high0 = u32CvtShim[1];
        wasm.__wbg_set_particle_collisions_count(this.ptr, low0, high0);
    }
    /**
    */
    get color() {
        const ret = wasm.__wbg_get_particle_color(this.ptr);
        return ret === 0 ? undefined : RGBA.__wrap(ret);
    }
    /**
    * @param {RGBA | undefined} arg0
    */
    set color(arg0) {
        let ptr0 = 0;
        if (!isLikeNone(arg0)) {
            _assertClass(arg0, RGBA);
            ptr0 = arg0.ptr;
            arg0.ptr = 0;
        }
        wasm.__wbg_set_particle_color(this.ptr, ptr0);
    }
    /**
    * @param {number} px
    * @param {number} py
    * @param {number} vx
    * @param {number} vy
    * @param {number} m
    * @param {number} r
    * @param {RGBA | undefined} color
    * @returns {Particle}
    */
    static new(px, py, vx, vy, m, r, color) {
        let ptr0 = 0;
        if (!isLikeNone(color)) {
            _assertClass(color, RGBA);
            ptr0 = color.ptr;
            color.ptr = 0;
        }
        const ret = wasm.particle_new(px, py, vx, vy, m, r, ptr0);
        return Particle.__wrap(ret);
    }
}
/**
*/
export class RGBA {

    static __wrap(ptr) {
        const obj = Object.create(RGBA.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_rgba_free(ptr);
    }
    /**
    */
    get red() {
        const ret = wasm.__wbg_get_rgba_red(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set red(arg0) {
        wasm.__wbg_set_rgba_red(this.ptr, arg0);
    }
    /**
    */
    get green() {
        const ret = wasm.__wbg_get_rgba_green(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set green(arg0) {
        wasm.__wbg_set_rgba_green(this.ptr, arg0);
    }
    /**
    */
    get blue() {
        const ret = wasm.__wbg_get_rgba_blue(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set blue(arg0) {
        wasm.__wbg_set_rgba_blue(this.ptr, arg0);
    }
    /**
    */
    get alpha() {
        const ret = wasm.__wbg_get_rgba_alpha(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set alpha(arg0) {
        wasm.__wbg_set_rgba_alpha(this.ptr, arg0);
    }
    /**
    * @param {number} red
    * @param {number} green
    * @param {number} blue
    * @param {number | undefined} _alpha
    * @returns {RGBA}
    */
    static new(red, green, blue, _alpha) {
        const ret = wasm.rgba_new(red, green, blue, !isLikeNone(_alpha), isLikeNone(_alpha) ? 0 : _alpha);
        return RGBA.__wrap(ret);
    }
    /**
    * @param {string} hex
    * @returns {RGBA | undefined}
    */
    static from_css_hex(hex) {
        const ptr0 = passStringToWasm0(hex, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
        const len0 = WASM_VECTOR_LEN;
        const ret = wasm.rgba_from_css_hex(ptr0, len0);
        return ret === 0 ? undefined : RGBA.__wrap(ret);
    }
    /**
    * @returns {string}
    */
    as_css_hex() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.rgba_as_css_hex(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return getStringFromWasm0(r0, r1);
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
            wasm.__wbindgen_free(r0, r1);
        }
    }
}
/**
*/
export class Segment {

    static __wrap(ptr) {
        const obj = Object.create(Segment.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_segment_free(ptr);
    }
    /**
    */
    get p1() {
        const ret = wasm.__wbg_get_segment_p1(this.ptr);
        return Vec2.__wrap(ret);
    }
    /**
    * @param {Vec2} arg0
    */
    set p1(arg0) {
        _assertClass(arg0, Vec2);
        var ptr0 = arg0.ptr;
        arg0.ptr = 0;
        wasm.__wbg_set_segment_p1(this.ptr, ptr0);
    }
    /**
    */
    get p2() {
        const ret = wasm.__wbg_get_segment_p2(this.ptr);
        return Vec2.__wrap(ret);
    }
    /**
    * @param {Vec2} arg0
    */
    set p2(arg0) {
        _assertClass(arg0, Vec2);
        var ptr0 = arg0.ptr;
        arg0.ptr = 0;
        wasm.__wbg_set_segment_p2(this.ptr, ptr0);
    }
    /**
    */
    get n() {
        const ret = wasm.__wbg_get_segment_n(this.ptr);
        return Vec2.__wrap(ret);
    }
    /**
    * @param {Vec2} arg0
    */
    set n(arg0) {
        _assertClass(arg0, Vec2);
        var ptr0 = arg0.ptr;
        arg0.ptr = 0;
        wasm.__wbg_set_segment_n(this.ptr, ptr0);
    }
    /**
    */
    get v() {
        const ret = wasm.__wbg_get_segment_v(this.ptr);
        return Vec2.__wrap(ret);
    }
    /**
    * @param {Vec2} arg0
    */
    set v(arg0) {
        _assertClass(arg0, Vec2);
        var ptr0 = arg0.ptr;
        arg0.ptr = 0;
        wasm.__wbg_set_segment_v(this.ptr, ptr0);
    }
    /**
    */
    get line() {
        const ret = wasm.__wbg_get_segment_line(this.ptr);
        return Line.__wrap(ret);
    }
    /**
    * @param {Line} arg0
    */
    set line(arg0) {
        _assertClass(arg0, Line);
        var ptr0 = arg0.ptr;
        arg0.ptr = 0;
        wasm.__wbg_set_segment_line(this.ptr, ptr0);
    }
    /**
    * @param {number} ax
    * @param {number} ay
    * @param {number} bx
    * @param {number} by
    * @returns {Segment}
    */
    static new(ax, ay, bx, by) {
        const ret = wasm.segment_new(ax, ay, bx, by);
        return Segment.__wrap(ret);
    }
}
/**
*/
export class Simulation {

    static __wrap(ptr) {
        const obj = Object.create(Simulation.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_simulation_free(ptr);
    }
    /**
    * @param {number} width
    * @param {number} height
    * @param {number} ticks_per_sec
    * @param {DrawParams | undefined} draw_params
    * @returns {Simulation}
    */
    static new(width, height, ticks_per_sec, draw_params) {
        let ptr0 = 0;
        if (!isLikeNone(draw_params)) {
            _assertClass(draw_params, DrawParams);
            ptr0 = draw_params.ptr;
            draw_params.ptr = 0;
        }
        const ret = wasm.simulation_new(width, height, ticks_per_sec, ptr0);
        return Simulation.__wrap(ret);
    }
    /**
    */
    tick() {
        wasm.simulation_tick(this.ptr);
    }
    /**
    * @param {Particle} particle
    * @returns {number | undefined}
    */
    add_particle(particle) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            _assertClass(particle, Particle);
            wasm.simulation_add_particle(retptr, this.ptr, particle.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return r0 === 0 ? undefined : r1 >>> 0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {Particle} particle
    * @param {string} player_uuid
    * @param {string} player_name
    * @param {Function} game_end_cb
    * @returns {number | undefined}
    */
    add_player_particle(particle, player_uuid, player_name, game_end_cb) {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            _assertClass(particle, Particle);
            const ptr0 = passStringToWasm0(player_uuid, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len0 = WASM_VECTOR_LEN;
            const ptr1 = passStringToWasm0(player_name, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
            const len1 = WASM_VECTOR_LEN;
            wasm.simulation_add_player_particle(retptr, this.ptr, particle.ptr, ptr0, len0, ptr1, len1, addHeapObject(game_end_cb));
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return r0 === 0 ? undefined : r1 >>> 0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @param {number} px
    * @param {number} py
    */
    mv_player_particle(px, py) {
        wasm.simulation_mv_player_particle(this.ptr, px, py);
    }
    /**
    * @param {Segment} segment
    */
    add_segment(segment) {
        _assertClass(segment, Segment);
        wasm.simulation_add_segment(this.ptr, segment.ptr);
    }
    /**
    * @param {CanvasRenderingContext2D} ctx
    */
    draw(ctx) {
        try {
            wasm.simulation_draw(this.ptr, addBorrowedObject(ctx));
        } finally {
            heap[stack_pointer++] = undefined;
        }
    }
    /**
    * @returns {boolean}
    */
    is_game_mode_enabled() {
        const ret = wasm.simulation_is_game_mode_enabled(this.ptr);
        return ret !== 0;
    }
    /**
    * @returns {number}
    */
    get_current_tick() {
        const ret = wasm.simulation_get_current_tick(this.ptr);
        return ret;
    }
    /**
    * @returns {number | undefined}
    */
    get_current_score() {
        try {
            const retptr = wasm.__wbindgen_add_to_stack_pointer(-16);
            wasm.simulation_get_current_score(retptr, this.ptr);
            var r0 = getInt32Memory0()[retptr / 4 + 0];
            var r1 = getInt32Memory0()[retptr / 4 + 1];
            return r0 === 0 ? undefined : r1 >>> 0;
        } finally {
            wasm.__wbindgen_add_to_stack_pointer(16);
        }
    }
    /**
    * @returns {any}
    */
    get_particles() {
        const ret = wasm.simulation_get_particles(this.ptr);
        return takeObject(ret);
    }
}
/**
*/
export class Vec2 {

    static __wrap(ptr) {
        const obj = Object.create(Vec2.prototype);
        obj.ptr = ptr;

        return obj;
    }

    __destroy_into_raw() {
        const ptr = this.ptr;
        this.ptr = 0;

        return ptr;
    }

    free() {
        const ptr = this.__destroy_into_raw();
        wasm.__wbg_vec2_free(ptr);
    }
    /**
    */
    get x() {
        const ret = wasm.__wbg_get_vec2_x(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set x(arg0) {
        wasm.__wbg_set_vec2_x(this.ptr, arg0);
    }
    /**
    */
    get y() {
        const ret = wasm.__wbg_get_vec2_y(this.ptr);
        return ret;
    }
    /**
    * @param {number} arg0
    */
    set y(arg0) {
        wasm.__wbg_set_vec2_y(this.ptr, arg0);
    }
}

export function __wbindgen_object_drop_ref(arg0) {
    takeObject(arg0);
};

export function __wbindgen_string_new(arg0, arg1) {
    const ret = getStringFromWasm0(arg0, arg1);
    return addHeapObject(ret);
};

export function __wbindgen_json_parse(arg0, arg1) {
    const ret = JSON.parse(getStringFromWasm0(arg0, arg1));
    return addHeapObject(ret);
};

export function __wbg_new_693216e109162396() {
    const ret = new Error();
    return addHeapObject(ret);
};

export function __wbg_stack_0ddaca5d1abfb52f(arg0, arg1) {
    const ret = getObject(arg1).stack;
    const ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
};

export function __wbg_error_09919627ac0992f5(arg0, arg1) {
    try {
        console.error(getStringFromWasm0(arg0, arg1));
    } finally {
        wasm.__wbindgen_free(arg0, arg1);
    }
};

export function __wbg_setfillStyle_1d391c4891a6ec4d(arg0, arg1) {
    getObject(arg0).fillStyle = getObject(arg1);
};

export function __wbg_beginPath_e040b5521d41f537(arg0) {
    getObject(arg0).beginPath();
};

export function __wbg_fill_b6e37fbbefb55ae0(arg0) {
    getObject(arg0).fill();
};

export function __wbg_stroke_63664360a52ce7d1(arg0) {
    getObject(arg0).stroke();
};

export function __wbg_arc_85205a36bd04df0a() { return handleError(function (arg0, arg1, arg2, arg3, arg4, arg5) {
    getObject(arg0).arc(arg1, arg2, arg3, arg4, arg5);
}, arguments) };

export function __wbg_closePath_efb14edfbbe7850a(arg0) {
    getObject(arg0).closePath();
};

export function __wbg_lineTo_e0f6cb3b8836cedb(arg0, arg1, arg2) {
    getObject(arg0).lineTo(arg1, arg2);
};

export function __wbg_moveTo_8d00712d6e75a749(arg0, arg1, arg2) {
    getObject(arg0).moveTo(arg1, arg2);
};

export function __wbg_clearRect_5e0469d0d11a7aee(arg0, arg1, arg2, arg3, arg4) {
    getObject(arg0).clearRect(arg1, arg2, arg3, arg4);
};

export function __wbg_log_e8ba7b992c7ad0eb(arg0) {
    console.log(getObject(arg0));
};

export function __wbg_call_3ed288a247f13ea5() { return handleError(function (arg0, arg1, arg2) {
    const ret = getObject(arg0).call(getObject(arg1), getObject(arg2));
    return addHeapObject(ret);
}, arguments) };

export function __wbindgen_debug_string(arg0, arg1) {
    const ret = debugString(getObject(arg1));
    const ptr0 = passStringToWasm0(ret, wasm.__wbindgen_malloc, wasm.__wbindgen_realloc);
    const len0 = WASM_VECTOR_LEN;
    getInt32Memory0()[arg0 / 4 + 1] = len0;
    getInt32Memory0()[arg0 / 4 + 0] = ptr0;
};

export function __wbindgen_throw(arg0, arg1) {
    throw new Error(getStringFromWasm0(arg0, arg1));
};

